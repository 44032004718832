import React from "react"

import Layout from "../../components/layout-secondary"
import Seo from "../../components/seo"
import { injectIntl } from "gatsby-plugin-react-intl"
import PartnerData from "../../content/partners/index.json"
import {Container} from "react-bootstrap";
import SimpleBlock from "../../components/misc/simple-block";
import HeroFeatures from "../../components/heroes/hero-features";
import MultiColBlock from "../../components/misc/multi-col-block";
import TextBlock from "../../components/misc/text-block";
import Testimonial from "../../components/misc/testimonial";
import PartnersTicker from "../../components/misc/partners-ticker"

const PartnersPage = ({ intl, location }) => (
  <Layout>
    <Seo lang={intl.locale} title={PartnerData[intl.locale].title} description={PartnerData[intl.locale].text} pathname={location.pathname} />
      <HeroFeatures
          theme={`secondary`}
          content={PartnerData[intl.locale]}
      />
      <Container>
          <TextBlock content={PartnerData[intl.locale].intro} />
      </Container>
      <Container>
          {PartnerData[intl.locale].blocks.map((item, i) => {

              return (
                  <SimpleBlock key={i} content={item} />
              )

          })}
      </Container>
      <PartnersTicker content={PartnerData[intl.locale].ticker} />
      <div className={`bg-light`}>
          <Container>
              <Testimonial content={PartnerData[intl.locale].testimonial}/>
          </Container>
      </div>
      <div className={`bg-white`}>
          <Container>
              <MultiColBlock content={PartnerData[intl.locale].callout} />
          </Container>
      </div>
  </Layout>
)

export default injectIntl(PartnersPage)
